<template>
  <div id="login">
    <div class="login_input">
      <h1>智慧环卫平台</h1>
      <div>
        <el-input v-model="username" placeholder="请输入账号"></el-input>
      </div>
      <div>
        <el-input
          placeholder="请输入密码"
          v-model="password"
          show-password
        ></el-input>
      </div>
      <div class="login_button">
        <el-button type="primary" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginAPI, GetLeftMenu } from "../request/api";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      // username: "13424489221",
      // password: "112233",
    };
  },
  computed: {},
  mounted() {
    // this.login()
  },
  methods: {
    //登录
    async login() {
      const base1 = this.encode(this.password);
      let basearr = base1.split("");
      let basearr0 = basearr[0];
      basearr[0] = basearr[2];
      basearr[2] = basearr0;
      const base2 = this.encode(basearr.join(""));
      let res = await LoginAPI({
        user_phone: this.username,
        password: base2,
      });

      if (res.code === 1) {
        console.log("ssssssssssssssss", res);

        sessionStorage.clear();
        sessionStorage.setItem("token", res.data.info.token);
        sessionStorage.setItem("user_name", res.data.info.user_name);
        sessionStorage.setItem("userInfo", JSON.stringify(res.data.info));
        sessionStorage.setItem(
          "default_point_info",
          JSON.stringify(res.data.default_point_info)
        );
        this.$store.commit("setusername", res.data.info.user_name);
        this.$store.commit("setUserInfo", res.data.info);
        this.$store.commit("setuserphone", res.data.info.user_phone);
        this.$router.push("/");
        this.$message({
          message: "登录成功",
          type: "success",
        });
        let res1 = await GetLeftMenu();

        console.log("11111111111111111111111111111111", res1);

        // <el-menu-item index="/setting" class="el-submenu__title"
        //     >设置</el-menu-item
        //   >
        //   <el-menu-item index="/index" class="el-submenu__title"
        //     >智慧环卫平台</el-menu-item
        //   >
        //   <el-menu-item index="/streetList" class="el-submenu__title"
        //     >街道管理</el-menu-item
        //   >
        //   <el-menu-item index="/log" class="el-submenu__title"
        //     >通讯日志</el-menu-item
        //   >

        // const MenuList = [
        //   {
        //     id: 7,
        //     name: "setting",
        //     pid: 0,
        //     remark: "设置",
        //   },
        //   {
        //     id: 8,
        //     name: "index",
        //     pid: 0,
        //     remark: "智慧环卫平台",
        //   },
        //   {
        //     id: 9,
        //     name: "streetList",
        //     pid: 0,
        //     remark: "街道管理",
        //   },
        //   {
        //     id: 10,
        //     name: "log",
        //     pid: 0,
        //     remark: "通讯日志",
        //   },
        // ];

        // const info = MenuList.filter((item) => {
        //   if (res1.data.info.user_type == 3) {
        //     if (item.id == 4) {
        //       item.second = [
        //         {
        //           id: 62,
        //           name: "test15",
        //           pid: 4,
        //           remark: "车务管理",
        //         },
        //       ];
        //     }

        //     return item.id == 8 || item.id == 4;
        //   } else {
        //     return item.id > 2;
        //   }
        // });

        // console.log("infoinfoinfo", info);

        // this.$store.commit("setmenuinfo", info);
      } else {
        this.$message.error(res.msg);
      }
    },
    encode(str) {
      if (str === undefined || str === "" || str === null) {
        return str;
      }
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100vh;
  background-color: #101838;
  display: flex;
  justify-content: center;
  align-items: center;

  .login_input {
    width: 400px;
    height: 400px;

    div {
      margin-top: 5px;

      .el-input__inner {
        background-color: #000 !important;
      }
    }

    .login_button {
      margin-top: 11px;
    }
  }

  h1 {
    color: #fff;
    font-size: 34px;
    text-align: center;
  }

  .el-button {
    width: 100%;
  }
}
</style>
